import axios from 'axios'
import store from '@/store/index.js'
import { Toast } from 'vant';

const service = axios.create({
  baseURL: store.getters.baseApiUrl,
  timeout: 5000, // 请求超时事件
})

// request interceptor
service.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token') || ''
    config.data = config.data || {}
    config.data.token = token
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 401) {
      Toast(res.msg || 'Error')
      return Promise.reject(res.msg)
    } else {
      return res
    }
  },
  error => {
    Toast(error.response.data.msg)
    return Promise.reject(error)
  }
)


export default service